import React, { Component } from "react";
import ServicesData from "../../data/services/servicesContentItems.json";

const PageTitleCommon = (props) => {
  const location = window.location;
  const [img, setImg] = React.useState("");
  const getProductImg = (productId, location) => {
    if (!location) return "";
    const path = location.pathname.split("/")[1];
    if (path === "service-inside") {
      const product = ServicesData.find((el) => el.id == productId);
      if (product) return `../../${product.imgSrc}`;
      return "";
    } else {
      if (path === "services") {
        return `../../assets/img/bg/heroImage4.jpg`;
      }
      if (path === "about-us") {
        return `../../assets/img/bg/heroImage2.jpg`;
      }
      if (path === "why-us") {
        return `../../assets/img/bg/heroImage3.jpg`;
      }
      if (path === "gallery") {
        return `../../assets/img/placeholder/1920x433.jpg`;
      }
      if (path === "contacts") {
        return `../../assets/img/bg/heroImage.jpg`;
      }
    }
  };
  React.useEffect(() => {
    setImg(getProductImg(props.productId, location));
  }, [props.productId, location]);

  return (
    <section
      id="page-title"
      className="block"
      style={{
        backgroundImage: !!img ? `url( ${img} )` : "transparent",
      }}
    >
      <div className="wrapper h-100">
        <div className="d-flex justify-content-between position-relative h-100">
          <div className="align-self-center">
            <div className="title">
              <h1>{props.title}</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="page-title-bg-color"></div>
    </section>
  );
};

export default PageTitleCommon;
