import React, { Component, useRef, useState } from "react";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import Typed from "react-typed";
import LanguageSelector from "../../components/button/LanguageSelector";


const PageTitleHome = (props) => {
  const { t } = props
  const [currentLng, setCurrentLng] = useState("en");



  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      localStorage.setItem("lang", "en")
    }
  }, [])


  const onChangeValue = (event) => {
    event.preventDefault()
    localStorage.setItem("lang", event.target.title);
    setCurrentLng(event.target.title)
    window.location.reload()
  }

  return (
    <section
      id="page-title"
      className="block"
      style={{
        backgroundImage: `url( ${require("../../assets/img/bg/heroImage.jpg")} )`,
      }}
    >
      <div className="wrapper h-100">
        <div className="d-flex justify-content-between position-relative h-100">
          <div className="align-self-center">
            <div className="title">
              <h1>
                Detonhail France <br />
              </h1>
              <h4>{t("slogan")}</h4>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span style={{ color: "white", paddingBottom: "0.5rem" }}>
                  {t("slogan_2")}
                </span>
                <Typed
                  style={{ color: "white" }}
                  strings={[
                    t("cortesy_car"),
                    t("tow_truck"),
                    t("sanitize"),
                    t("window_replacement"),
                  ]}
                  typeSpeed={40}
                  loop
                />
              </div>
            </div>

            {/* <p className="spacer p-top-lg mb-0">
              Con esperienza e passione,da sempre.
            </p> */}
          </div>

          <div className="lang-position">
            <nav className="languages">
              <ul className="nav" onClick={onChangeValue}>
                <LanguageSelector
                  title="en"
                  className={currentLng === "en" ? "current-lang" : ""}
                />
                <LanguageSelector
                  title="it"
                  className={currentLng === "it" ? "current-lang" : ""}
                />
                <LanguageSelector
                  title="fr"
                  className={currentLng === "fr" ? "current-lang" : ""}
                />
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className="page-title-bg-color"></div>
    </section>
  );
}


// class PageTitleHome extends Component {
//   constructor() {
//     super();
//     this.state = {
//       lang: "En",
//     };
//     localStorage.setItem("lang", "it");
//     this.onChangeValue = this.onChangeValue.bind(this);
//   }

//   onChangeValue(event) {
//     localStorage.setItem("lang", event.target.title);
//     this.setState({
//       lang: event.target.title,
//     });
//   }

//   render() {
//     const { t } = this.props
//     return (
//       <section
//         id="page-title"
//         className="block"
//         style={{
//           backgroundImage: `url( ${require("../../assets/img/bg/heroImage.jpg")} )`,
//         }}
//       >
//         <div className="wrapper h-100">
//           <div className="d-flex justify-content-between position-relative h-100">
//             <div className="align-self-center">
//               <div className="title">
//                 <h1>
//                   Detonhail France <br />
//                 </h1>
//                 <h4>{t("slogan")}</h4>
//                 <div style={{ display: "flex", flexDirection: "column" }}>
//                   <span style={{ color: "white", paddingBottom: "0.5rem" }}>
//                     {t("slogan_2")}
//                   </span>
//                   <Typed
//                     style={{ color: "white" }}
//                     strings={[
//                       t("cortesy_car"),
//                       t("tow_truck"),
//                       t("sanitize"),
//                       t("window_replacement"),
//                     ]}
//                     typeSpeed={40}
//                     loop
//                   />
//                 </div>
//               </div>

//               {/* <p className="spacer p-top-lg mb-0">
//                 Con esperienza e passione,da sempre.
//               </p> */}
//             </div>

//             <div className="lang-position">
//               <nav className="languages">
//                 <ul className="nav" onClick={this.onChangeValue}>
//                   <LanguageSelector
//                     title="en"
//                     className={this.state.lang === "en" ? "current-lang" : ""}
//                   />
//                   <LanguageSelector
//                     title="it"
//                     className={this.state.lang === "it" ? "current-lang" : ""}
//                   />
//                   <LanguageSelector
//                     title="fr"
//                     className={this.state.lang === "fr" ? "current-lang" : ""}
//                   />
//                 </ul>
//               </nav>
//             </div>
//           </div>
//         </div>

//         <div className="page-title-bg-color"></div>
//       </section>
//     );
//   }
// }

export default withTranslation()(PageTitleHome);
