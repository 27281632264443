import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    it: {
        translation: {
            "nav_services": "Servizi",
            "nav_about_us": "Chi siamo",
            "nav_why_choose_us": "Perchè scegliere noi",
            "nav_contacts": "Contatti",
            "slogan": "Non temere più la grandine",
            "slogan_2": "Diamo valore alla tua auto",
            "services": "Servizi",
            "cortesy_car": "Auto di cortesia",
            "tow_truck": "Carroattrezzi",
            "sanitize": "Sanificazione",
            "window_replacement": "Sostituzione cristalli",
            "about_us_title": "Chi Siamo",
            "about_us_first_desc": "Dopo anni di esperienza in Italia e in Europa con più di 15.000 auto riparate all'anno con tecnica PDR ( Paintless Dent Repair ) e con supporto di carrozzerie proprie e allestite per l'emergenza, abbiamo dato supporto ai più grandi marchi automobilistici e alle più importanti società presenti sul mercato dove l'evento grandigeno ha creato disagi enormi ai clienti delle compagnie assicurative.",
            "about_us_second_desc": "La DetonHail vanta personale di carrozzeria certificato ed altamente specializzato per la rimozione di bolli, ai quali si aggiungono le più innovative tecnologie del settore. Detonhail dispone inoltre di un parco auto da utilizzare come vetture sostitutive per i clienti al momento della presa in custodia della vettura da riparare.",
            "about_us_first_footer": "Dopo anni di esperienza in Italia e in Europa con più di 15.000 auto riparate all'anno con tecnica PDR ( Paintless Dent Repair )...",
            "why_choose_us": "Perchè scegliere noi",
            "experience": "Esperienza",
            "price": "Prezzi",
            "responsibility": "Responsabilità",
            "transparency": "Trasparenza",
            "versatility": "Versatilità",
            "speed": "Velocità",
            "showroom": "Showroom",
            "contacts_title": "Contatti",
            "where_we_are": "Dove siamo",
            "monday": "Lun",
            "friday": "Ven",
            "fill_in_the_form": "Compila il form"
        }
    },
    en: {
        translation: {
            "nav_services": "Services",
            "nav_about_us": "About us",
            "nav_why_choose_us": "Why choose us",
            "nav_contacts": "Contacts",
            "slogan": "Fear no more hail",
            "slogan_2": "We value your car",
            "services": "Services",
            "cortesy_car": "Cortesy car",
            "tow_truck": "Tow truck",
            "sanitize": "Disinfection",
            "window_replacement": "Window replacement",
            "about_us_title": "About us",
            "about_us_first_desc": "After years of experience in Italy and in Europe with more than 15,000 cars repaired a year with the PDR (Paintless Dent Repair) technique and with the support of our own body shops and equipped for emergencies, we have given support to the biggest car brands and the most important companies present on the market where the catastrophic event has created enormous inconvenience for the customers of the insurance companies.",
            "about_us_second_desc": "DetonHail boasts certified and highly specialized bodywork personnel for the removal of stamps, to which are added the most innovative technologies in the sector. Detonhail also has a fleet of cars to be used as replacement cars for customers when the car to be repaired is taken into custody.",
            "about_us_first_footer": "After years of experience in Italy and in Europe with more than 15,000 cars repaired a year with the PDR (Paintless Dent Repair)...",
            "why_choose_us": "Why choose us",
            "experience": "Experience",
            "price": "Price",
            "responsibility": "Responsibility",
            "transparency": "Transparency",
            "versatility": "Versatility",
            "speed": "Speed",
            "showroom": "Showroom",
            "contacts_title": "Contacts",
            "where_we_are": "Where we are",
            "monday": "Mon",
            "friday": "Fry",
            "fill_in_the_form": "Fill in the form"
        }
    },
    fr: {
        translation: {
            "nav_services": "Services",
            "nav_about_us": "A propos de nous",
            "nav_why_choose_us": "Pourquoi nous choisir",
            "nav_contacts": "Contact",
            "slogan": "Ne craignez plus la grêle",
            "slogan_2": "Nous donnons de l’importance à votre voiture",
            "services": "Prestations de service",
            "cortesy_car": "Auto de courtoisie",
            "tow_truck": "Dépanneuse",
            "sanitize": "Désinfection",
            "window_replacement": "Remplacement de pare-brise",
            "about_us_title": "Qui sommes-nous ?",
            "about_us_first_desc": "Après des années d'expérience en Italie et en Europe avec plus de 15 000 voitures réparées par an Avec la technique de débosselage sans peinture (PDR Paintless Dent Repair) et avec le soutien de nos propres ateliers de carrosserie et équipés pour les urgences, nous avons soutenu les plus grandes marques automobiles et les plus importantes compagnies présentes sur le marché où l'événement catastrophique a créé d'énormes désagréments pour les clients des compagnies d'assurance.",
            "about_us_second_desc": "DetonHail Dispose d'un personnel de carrosserie certifié et hautement spécialisé dans l'élimination de bosses causées par la grêle et utilise les technologies les plus innovantes du secteur.",
            "about_us_first_footer": "Après des années d'expérience en Italie et en Europe avec plus de 15 000 voitures réparées par an Avec la technique de débosselage sans peinture (PDR Paintless Dent Repair)...",
            "why_choose_us": "Pourquoi nous choisir",
            "experience": "Expérience",
            "price": "Des prix",
            "responsibility": "Responsabilité",
            "transparency": "Transparence",
            "versatility": "Polyvalence",
            "speed": "La rapidité",
            "showroom": "Showroom",
            "contacts_title": "Contact",
            "where_we_are": "Où sommes-nous ?",
            "monday": "Lundi",
            "friday": "Vendredi",
            "fill_in_the_form": "Remplir le formulaire"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "it",
        lng: localStorage.getItem("lang"), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;