import React from "react";
import logo from "../../assets/img/logo/logoBlank.png";
import logoWhite from "../../assets/img/logo/logoWhite.png";
const HeaderLogo = ({ logoColor }) => {
  return (
    <a
      className="logo logo-primary transform-scale-h"
      title="Logo"
      href={process.env.PUBLIC_URL + "/"}
    >
      <img
        width="120"
        className={"logo-primary-" + (logoColor === "light" ? "light" : "dark")}
        src={logoColor === "light" ? logoWhite : logo}
        alt="Logo"
      />
    </a>
  );
};

export default HeaderLogo;
