import React from "react";
import { withTranslation } from 'react-i18next';

const LinkTitle = (props) => {
  const { t } = props
  return (
    <a
      title="About us"
      className="transform-scale-h"
      href={`${process.env.PUBLIC_URL + "/about-us"}`}
    >
      {t("about_us_title")}

      <i className="fas fas-space-l fa-long-arrow-alt-right align-top"></i>
    </a>
  );
};

const TextTitle = (props) => {
  const { t } = props
  return <div title="About us">{t("about_us_title")}</div>;
};

const AboutUsTitle = (props) => {
  const isHomepage = props.isHomepage;


  if (isHomepage) {
    return <LinkTitle t={props.t} />;
  }
  return <TextTitle t={props.t} />;
};

const AboutUsPrimary = (props) => {
  const { t } = props

  return (
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 align-self-center">
      <div className="title">
        <h2>
          <AboutUsTitle t={t} isHomepage={props.isHomepage} />
        </h2>
      </div>

      <div className="text">
        <p>
          {t("about_us_first_desc")}
        </p>
        <p>
          {t("about_us_second_desc")}
        </p>
      </div>

      {/* <div className="list-group list-group-horizontal spacer m-top-lg m-bottom-lg-media-xl style-default">
        <div className="list-group-item">
          <h4 className="text-secondary">03</h4>
          <p>Dato</p>
        </div>

        <div className="list-group-item">
          <h4 className="text-secondary">25</h4>
          <p>Dato</p>
        </div>

        <div className="list-group-item">
          <h4 className="text-secondary">154</h4>
          <p>Dato</p>
        </div>
      </div> */}
    </div>
  );
};

export default withTranslation()(AboutUsPrimary);
