import React from "react";

const Copyright = () => {
  return (
    <div className="copyright" style={{ textAlign: "center" }}>
      <p>© 2022 Detonhail France</p>
      <a target={"_blank"} style={{ cursor: "pointer" }} href="https://www.aldonoschese.com">
        Aldo Noschese - Professional Web Developer
      </a>
    </div>
  );
};

export default Copyright;
