import React from "react";
import { withTranslation } from "react-i18next";

import WhyUsPdf from "./WhyUsPdf";

const WhyUsContent = (props) => {
  const { t } = props;
  return (
    <div className="wrapper">
      <div className="content">
        <div className="clearfix">
          <div className="row gutter-width-lg with-pb-lg style-default">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <h4 className="text-secondary">01</h4>
              <p className="text-primary p-large bold">{t("experience")}</p>
              {/* <p>
                On the other hand, we denounce with righteous indignation and
                dislike men who are so beguiled and demoralized by the charms.
              </p> */}
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <h4 className="text-secondary">02</h4>
              <p className="text-primary p-large bold">{t("transparency")}</p>
              {/* <p>
                On the other hand, we denounce with righteous indignation and
                dislike men who are so beguiled and demoralized by the charms.
              </p> */}
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <h4 className="text-secondary">03</h4>
              <p className="text-primary p-large bold">{t("price")}</p>
              {/* <p>
                On the other hand, we denounce with righteous indignation and
                dislike men who are so beguiled and demoralized by the charms.
              </p> */}
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <h4 className="text-secondary">04</h4>
              <p className="text-primary p-large bold">{t("versatility")}</p>
              {/* <p>
                On the other hand, we denounce with righteous indignation and
                dislike men who are so beguiled and demoralized by the charms.
              </p> */}
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <h4 className="text-secondary">05</h4>
              <p className="text-primary p-large bold">{t("responsibility")}</p>
              {/* <p>
                On the other hand, we denounce with righteous indignation and
                dislike men who are so beguiled and demoralized by the charms.
              </p> */}
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <h4 className="text-secondary">06</h4>
              <p className="text-primary p-large bold">{t("speed")}</p>
              {/* <WhyUsPdf /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(WhyUsContent)

