import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { Accordion, Card, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

class ContactsAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active1: false,
      active2: false,
      active3: false,
    };
  }

  clickHandler1(e) {
    this.setState({
      active1: !this.state.active1,
      active2: false,
      active3: false,
    });
  }

  clickHandler2(e) {
    this.setState({
      active2: !this.state.active2,
      active1: false,
      active3: false,
    });
  }

  clickHandler3(e) {
    this.setState({
      active3: !this.state.active3,
      active1: false,
      active2: false,
    });
  }

  componentDidMount() {
    let box = document.getElementById("maps-information");

    if (window.innerWidth < 1200) {
      box.style.overflow = "hidden";
    } else {
      box.style.overflow = "initial";
    }
  }

  render() {
    const { t } = this.props
    return (
      <div className="wrapper">
        <div id="maps-information" className="maps-information">
          <Scrollbars
            autoHeight
            autoHeightMin={0}
            autoHeightMax={449}
            thumbMinSize={30}
            style={{
              width:
                this.state.active1 || this.state.active2 || this.state.active3
                  ? "102%"
                  : "100%",
            }}
            renderThumbVertical={({ style, ...props }) => (
              <div
                {...props}
                style={{
                  ...style,
                  backgroundColor: "#414258",
                  opacity:
                    this.state.active1 ||
                      this.state.active2 ||
                      this.state.active3
                      ? 1
                      : 0,
                }}
              />
            )}
          >
            <Accordion
              id="accordion-maps"
              style={{
                width:
                  this.state.active1 || this.state.active2 || this.state.active3
                    ? "98%"
                    : "100%",
              }}
            >
              <Card>
                <Card.Header id="heading-2">
                  <Accordion.Toggle
                    as={Button}
                    style={{ color: "#414258" }}
                    onClick={(e) => this.clickHandler2(e)}
                    variant="link"
                    value="2"
                    eventKey="2"
                    className={`btn btn-lg btn-link stretched-link ${this.state.active2 ? "" : "collapsed"
                      }`}
                    data-placeid="ChIJA9KNRIL-1BIRb15jJFz1LOI"
                    aria-expanded={this.state.active2 ? "true" : "false"}
                  >

                    {t("where_we_are")}<i className="fas fa-long-arrow-alt-up"></i>
                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse
                  eventKey="2"
                  id="collapse-2"
                  aria-labelledby="heading-2"
                  data-parent="#accordion-maps"
                >
                  <Card.Body>
                    <p>4 rue de la Voivre, 25490 Fesches le-chatel</p>
                    <p>06.50.37.19.58</p>
                    <p>
                      <a href="mailto:infissidamato.a@gmail.com">
                        info@detonhail.fr
                      </a>
                    </p>
                    <p>{t("monday")} - {t("friday")}: 8:30am - 17pm</p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Scrollbars>
        </div>
      </div>
    );
  }
}


export default withTranslation()(ContactsAccordion);
