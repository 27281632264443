import React from "react";

import FooterMenu from "../footer/FooterMenu";

const FooterWidget = ({ t }) => {
  return (
    <div className="footer-widgets">
      <div className="footer-widget-area d-flex flex-wrap">
        <div className="widget">
          <h5 className="widget-title">{t("nav_about_us")}</h5>

          <p>
            {t("about_us_first_footer")}

          </p>
        </div>

        <div className="widget">
          <h5 className="widget-title">Menu</h5>

          <FooterMenu t={t} />
        </div>

        <div className="widget">
          <h5 className="widget-title">{t("")}</h5>

          <p>4 rue de la Voivre, 25490 Fesches le-chatel</p>

          <p>
            <a href="mailto:info@info.com">info@detonhail.fr</a>
          </p>

          <p>06.50.37.19.58</p>
        </div>
      </div>
    </div>
  );
};

export default FooterWidget;
