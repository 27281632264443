import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class HeaderMenuPrimary extends Component {
  constructor(props) {
    super();
    this.state = {
      over: false,
    };
  }

  handleOver = () => {
    this.setState({ over: !this.state.over });
  };

  render() {
    const { over } = this.state;
    const { t } = this.props;

    return (
      <nav className="menu-primary">
        <ul className="nav">
          <li className="nav-item">
            <Link to={"/"} title="Home">
              Home
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/services") ||
                window.location.pathname.includes("/service-inside")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={"/services"} title="Servizi">
              {t("nav_services")}
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/about-us")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={"/about-us"} title="Chi siamo">
              {t("nav_about_us")}
            </Link>
          </li>

          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/why-us")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={"/why-us"} title="Why us">
              {t("nav_why_choose_us")}
            </Link>
          </li>

          {/* <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/gallery")
                ? "current-nav-item"
                : "")
            }
            id="nav-gallery"
          >
            <Link to={"/gallery"} title="Gallery" href="#gallery">
              Showroom
            </Link>
          </li> */}
          {/* <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/clients")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={"/clients"} title="Clients">
              Clienti
            </Link>
          </li> */}
          <li
            className={
              "nav-item " +
              (window.location.pathname.includes("/contacts")
                ? "current-nav-item"
                : "")
            }
          >
            <Link to={"/contacts"} title="Contatti">
              {t("nav_contacts")}
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default withTranslation()(HeaderMenuPrimary);
